@media print {
    #printPageButton {
        display: none;
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: white;
        page-break-after: always;
    }
    
    .myDivToPrint {
        height: 100%;
        width: 100%;
        position:absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 10px;
        background-color: #FAFAFA;
        font: 10pt "Tahoma";
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: white;
        page-break-after: always;
    }
}






